import React from 'react';
import './Spinner.css';

const spinner = (props) => {
    let spinner;
    props.show ? spinner = <div className="main-spinner">
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div> : spinner = null;
    return spinner;
}

export default spinner;


