import React, {Component, Fragment} from 'react';
import './Radar.css';
import Startbasic from '../Startpages/Basic/Startbasic';
import Startexplorer from '../Startpages/Explorer/Startexplorer';
import RadarAreas from '../UI/RadarAreas/RadarAreas';
import ErrorImage from '../../assets/error.PNG';

const compositWidth = 800;

class Radar extends Component {

    //gebraucht für MapArea in Composit/CompositXXL
    state = {};
    constructor(props) {
        super(props);
        this.state.radarWidth = 800;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.radarImage !== nextProps.radarImage ||
            this.props.gpsOverlayMap !== nextProps.gpsOverlayMap ||
            this.props.backgroundMap !== nextProps.backgroundMap ||
            this.state.radarWidth !== nextState.radarWidth ||
            this.props.showDesktop !== nextProps.showDesktop ||
            this.props.viewMode !== nextProps.viewMode) {
            if (this.props.radarImage !== nextProps.radarImage
                && this.props.backgroundMap !== nextProps.backgroundMap
                && nextProps.backgroundMap && nextProps.radarImage)
            {
                this.anzLoaded = 2;
                return true;
            }
            if (this.props.gpsOverlayMap !== nextProps.gpsOverlayMap && nextProps.gpsOverlayMap && (
                    this.props.backgroundMap === nextProps.backgroundMap &&
                    this.props.radarImage === nextProps.radarImage))
            {
                this.anzLoaded = 1;
                return true;
            }
            return true;
        } else {
            return false;
        }
    }

    defineMapAreas = (el) => {
        if (el) this.radarImage = el;
        if (this.radarImage) {
            this.setState({radarWidth: this.radarImage.clientWidth});
        }
    };

    radarClickedHandler = () => {
        if (this.props.mode === 'basic' &&
            this.props.radarImage.search('10000') === -1 &&
            this.props.radarImage.search('10001') === -1 &&
            this.props.type === 'dx') {
            this.props.compositClicked({station: '10000'});
        }
    };

    render() {
        let backgroundElement, gpsOverlay, classes = 'main-content__radar-responsive', containerInlineStyle = {},
            mapArea;
        if (this.props.radarImage) {
            //Composit XL wird scrollbar angezeigt
            if (this.props.radarImage.search('10001') !== -1 || this.props.viewMode === 'full') {
                classes = 'main-content__radar-full';
                //Max-Width wird dynamisch gesetzt im Explorermodus bzw. immer auf 800px im Basic (sonst streckung big screen)
            } else {
                classes = 'main-content__radar-responsive';
                if (this.props.radarImageWidth) {
                    containerInlineStyle.width = this.props.radarImageWidth + 'px';
                } else if (this.props.mode === 'basic') {
                    containerInlineStyle.width = '800px';
                }
            }
            if (this.props.radarImage.search('10001') !== -1 || this.props.radarImage.search('10000') !== -1) {
                const ratio = this.state.radarWidth / compositWidth;
                mapArea = <RadarAreas compositClicked={this.props.compositClicked} ratio={ratio}/>
            } else {
                mapArea = null;
            }
            if (!this.props.showDesktop) {
                containerInlineStyle.maxWidth = '100%';
            }
        }
        if (this.props.backgroundMap) {
            backgroundElement = <img
                alt="Background"
                src={this.props.backgroundMap}
                onLoad={()=>{
                    if (this.anzLoaded === 2) {
                        if (this.props.stopSpinner && this.whatLoaded === 'radar') {
                            this.whatLoaded = '';
                            this.anzLoaded = 0;
                            this.props.stopSpinner();
                        } else {
                            this.whatLoaded = 'background';
                        }
                    } else {
                        this.props.stopSpinner();
                    }
                }}/>;
        } else {
            backgroundElement = null;
        }
        if (this.props.gpsOverlayMap) {
            gpsOverlay =
                <img alt="Position"
                     src={this.props.gpsOverlayMap}
                     onLoad={()=>{
                         if (this.anzLoaded === 1) {
                             this.props.stopSpinner();
                             this.anzLoaded = 0;
                         }
                     }}
                />;
        } else {
            gpsOverlay = null;
        }
        if (this.props.radarImage) {
            return (
                <Fragment>
                    <div className={classes} style={containerInlineStyle}>
                        {backgroundElement}
                        <img alt="Radar"
                             src={this.props.radarImage}
                             onLoad={()=>{
                                 if (this.anzLoaded === 2) {
                                     if (this.props.stopSpinner && this.whatLoaded === 'background') {
                                         this.whatLoaded = '';
                                         this.anzLoaded = 0;
                                         this.props.stopSpinner();
                                     } else {
                                         this.whatLoaded = 'radar';
                                     }
                                 } else {
                                     this.props.stopSpinner();
                                 }
                                 this.defineMapAreas();
                                 if (this.props.startRadarAnimation) {
                                    if(this.props.isLoop) this.props.startRadarAnimation();
                                 }
                             }}
                             onErrorCapture={(e)=>{
                                 e.target.src = ErrorImage;
                                 this.props.stopSpinner();

                             }}
                             useMap="#ClickComposit"
                             ref={this.defineMapAreas}
                             onClick={this.radarClickedHandler}
                        />
                        {gpsOverlay}
                        {mapArea}
                    </div>
                </Fragment>
            )
        } else {
            return this.props.mode === 'basic' ?
                <Startbasic
                    startImagesArray={this.props.startImagesArray}
                    startImageClicked={this.props.compositClicked}/>
                : <Startexplorer/>
        }
    }
}

export default Radar;