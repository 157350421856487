import React, {Fragment} from 'react';
import './Topbar.css';
import { NavLink } from 'react-router-dom';
import Toggle from '../../UI/Toggle';
import SkywarnLogo from '../../../assets/skywarn_logo.gif';

const location = window.location;

const topbar = (props) => {
    let mobileNavClass;
    props.show === true ? mobileNavClass = 'main-nav-mobile-active' : mobileNavClass = 'main-nav-mobile';
    return (
        <Fragment>
            <header className="main-header">
                <div className="main-header__logo">
                    <img src={SkywarnLogo} alt="Skywarn"/>
                    <div>IRAS Public - Skywarn Deutschland e.V.</div>
                    <Toggle where="topbar" toggle={props.toggle}/>
                </div>
                <nav className="main-nav">
                    <ul className="main-nav__items">
                        <NavLink className="main-nav__item"
                                 activeClassName="main-nav__item-active"
                                 to="/public" onClick={props.toggle}>Public
                        </NavLink>                        
                        <NavLink className="main-nav__item"
                                 activeClassName="main-nav__item-active"
                                 to="/maps" onClick={props.toggle}>Maps</NavLink>    
                        <a class="main-nav__item" href={location.origin + "/intern"} target="_blank" rel="noreferrer">Intern</a>                              
                    </ul>
                </nav>
            </header>
            <nav className={mobileNavClass}>
                <ul className="main-nav__items">
                    <NavLink className="main-nav__item" activeClassName="main-nav__item-active"
                             to="/public" onClick={props.toggle}>Public</NavLink>   
                    <NavLink className="main-nav__item" activeClassName="main-nav__item-active"
                             to="/maps" onClick={props.toggle}>Maps</NavLink>      
                    <a class="main-nav__item" href={location.origin + "/intern"} target="_blank" rel="noreferrer">Intern</a>                 
                </ul>
            </nav>
        </Fragment>
    )
};

export default topbar;