import modifyCurrentTimestamp from "./modifyCurrentTimestamp";

// gibt ein Objekt mit den Zeitdaten zurück, die notwendig für die URL sind
// wird timestamp nicht übergeben, wird der aktuelle Timestamp verwendet und abgerundet
// wird downTo0Or5 als true übergeben, wird der timestamp angepasst auf die passenden x0 oder x5 Minuten,
// zu denen ein Radarbild vorhanden sein kann (z.b. 11:23 als timestamp macht 11:15..)

function modifyTimestamp(timestamp, downTo0Or5) {
    let calcedTime = {}, calcDateForRadarImage;
    if (timestamp === undefined) {
        //aktuellen Zeitstamp überprüfen und für Grafikbenennungen anpassen (0/5 Minuten)
        const currentDate = new Date();
        calcDateForRadarImage = modifyCurrentTimestamp(currentDate);
        this.currentTimestamp = calcDateForRadarImage.getTime();
        this.timestampSingleImage = calcDateForRadarImage.getTime();
    } else {
        const dateFromTimestamp = new Date(timestamp);
        if (downTo0Or5) {
            calcDateForRadarImage = modifyCurrentTimestamp(dateFromTimestamp);
        } else {
            calcDateForRadarImage = dateFromTimestamp;
        }
    }
    calcedTime.year = calcDateForRadarImage.getFullYear();
    const month = calcDateForRadarImage.getMonth() + 1;
    const day = calcDateForRadarImage.getDate();
    const hour = calcDateForRadarImage.getHours();
    const minutes = calcDateForRadarImage.getMinutes();

    month < 10 ? calcedTime.month = `0${month}` : calcedTime.month = month.toString();
    day < 10 ? calcedTime.day = `0${day}` : calcedTime.day = day.toString();
    hour < 10 ? calcedTime.hour = `0${hour}` : calcedTime.hour = hour.toString();
    minutes < 10 ? calcedTime.minute = `0${minutes}` : calcedTime.minute = minutes.toString();

    return calcedTime;
}

export default modifyTimestamp;