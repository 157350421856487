import React, {Fragment} from 'react';
import './RadarControlAnimation.css';
import RadarControlButton from '../../../../UI/RadarControlButton/RadarControlButton';

const radarcontrolanimation = (props) => {
    return (
        <Fragment>
            <RadarControlButton clickHandler={props.stopRadarAnimation} active={!props.isLoop}>
                <i className="fas fa-pause"></i>
            </RadarControlButton>
            <RadarControlButton clickHandler={props.continueRadarAnimation} active={props.isLoop}>
                <i className="fas fa-play"></i>
            </RadarControlButton>
            <RadarControlButton clickHandler={() => {props.changeAnimationSpeed(true);}}>+ Speed</RadarControlButton>
            <div className="main-content__control__radar-control__speed">
                {1000 / props.animationSpeed } pro s
            </div>
            <RadarControlButton clickHandler={() => {props.changeAnimationSpeed(false);}}>- Speed</RadarControlButton>
            <RadarControlButton clickHandler={() => {props.radarAnimationHandler(false);}}>
                <i className="fas fa-backward"></i>
            </RadarControlButton>
            <RadarControlButton clickHandler={() => {props.radarAnimationHandler(true);}}>
                <i className="fas fa-forward"></i>
            </RadarControlButton>
            <RadarControlButton clickHandler={() => {props.setNewestImage();}}>
                <i className="fas fa-sync"></i>
            </RadarControlButton>
        </Fragment>
    );
};

export default radarcontrolanimation;


