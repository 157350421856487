import React from 'react';
import './Time.css';
import SidebarButton from '../../../UI/SidebarButton/SidebarButton';


const time = (props) => {
    let selectHour = [], selectMinutes = [],
        optionHour = [], optionMinutes = [], autoReloadButton;
    for (let i = 0; i < 24; i++) {
        selectHour.push(i);
        if (i < 12) {
            selectMinutes.push(i * 5);
        }
    }
    optionHour = selectHour.map(value => {
        if (value < 10 ) {
            value = '0' + value;
        }
        return <option value={value} key={value}>{value}</option>
    });
    optionMinutes = selectMinutes.map(value => {
        if (value === 0 || value === 5) {
            value = '0' + value;
        }
        return <option value={value} key={value}>{value}</option>
    });
    props.showAutoReload ? autoReloadButton = <SidebarButton
        active={props.autoReload}
        updateState={props.toggleAutoReload}
    >Autoreload
    </SidebarButton> : autoReloadButton = null;
    return (
        <div className="main-content__control__time">
            <select name="Stunde" value={props.hour} onChange={(event) => {
                return props.updateState('hour', event.target.value);
            }}>
                {optionHour}
            </select>
            <select name="Minute" value={props.minute} onChange={(event) => {
                return props.updateState('minute', event.target.value);
            }}>
                {optionMinutes}
            </select>
            {autoReloadButton}
        </div>
    );
}

export default time;