import React, {Component, Fragment} from 'react';
import Sidebar from '../../components/Navigation/Sidebar/Sidebar';
import Radar from '../../components/Radar/Radar';
import RadarControl from '../../components/Navigation/Sidebar/RadarControl/RadarControl';
import './Public.css';
import Spinner from "../../components/UI/Spinner/Spinner";
import modifyTimestamp from "../../common/modifyTimestamp";
import {OVERLAY, OVERLAY_LOOP, BACKGROUND_LOCATION, RADARIMG_PATH} from "../../constants/constants"

class Basic extends Component {

    // Daten, welche bei Aktualieserung keine DOM-Renderung bewirken sollen
    constBackgroundMap = BACKGROUND_LOCATION; //800.gif';
    constRadarImage = RADARIMG_PATH;
    radarImageArray = [];
    currentIndex = 0;
    timeoutId = 0;
    //timestamp beim Komponenten-Mount
    currentTimestamp;
    //timestamp des Radarbildes ohne Loop (benötigt für entsprechendes Radarcontrol)
    timestampSingleImage;

    state = {};

    constructor(props) {
        super(props);
        this.modifyTimestamp = modifyTimestamp.bind(this);
        const calcedTime = this.modifyTimestamp();
        this.state.year = calcedTime.year;
        this.state.month = calcedTime.month;
        this.state.day = calcedTime.day;
        this.state.hour = calcedTime.hour;
        this.state.minute = calcedTime.minute;       
        this.state.station = '10000'; //normal groß
        this.state.animation = 0;
        this.state.speed = 1000;
        this.state.type = 'pg';
        this.state.map = '_topo_color_';
        //this.state.startImagesArray = this.createStartpageImages(calcedTime);
        this.state.viewMode = 'responsive';
        this.state.isLoop = true;
        this.state.showSidebar = false;
        this.state.showSidebarDesktop = true;
        this.state.loadNewClicked = false;
        this.state.autoReload = false;
        this.state.showSpinner = false;
        this.state.backgroundMap = null;
        this.state.gpsOverlayMap = null;
        this.state.radarImages =  [];
        this.state.currentRadarImage = '';
    }

    componentDidMount() {
        this.loadImage(true);
    }

    componentWillUnmount() {
        this.clearAutoReload();
    }

    updateState = (prop, value) => {
        this.clearAutoReload();
        const newState = {}, oldStation = this.state.station;
        //fast immer auf false setzen
        newState.autoReload = false;
        if (prop !== 'viewMode' && prop !== 'showSidebar') {
            newState["loadNewClicked"] = false;
        }
        newState[prop] = value;
        this.setState(newState);
    };

    compositClicked = (changeObj) => {
        
    }

    //erzeugt den RadarBildString aus einem übergebenen Timestamp und dem aktuellen State
    //bzw. aus dem calcedTime - Object und einem alternativen State, was mit alternative === true vermerkt wird

    createImageString = (timestamp, altState, alternative) => {
        let calcedTime, fileType, prefix, oldState;       
        if (alternative) {
            oldState = altState;
            calcedTime = timestamp;
        } else if (altState) {
            oldState = altState;
            calcedTime = this.modifyTimestamp(timestamp, false);
        } else {
            oldState = this.state;
            calcedTime = this.modifyTimestamp(timestamp, false);
        }
        fileType = '.gif';
        oldState.station === "10001" ? prefix = "public_large_" : prefix = "public_";
        return (this.constRadarImage + prefix + calcedTime.year + calcedTime.month + calcedTime.day 
            + "_" + calcedTime.hour + calcedTime.minute + fileType);
    };

    createRadarArray = (duration, altState) => {
        let oldState;
        if (altState) {
            oldState = altState;
        } else {
            oldState = this.state;
        }
        this.setState({isLoop:true});
        // Timestamp aus State errechnen für einfache Berechnung von Vergangenheitsdaten
        let stateTimestamp = new Date(
            oldState.year, oldState.month - 1, oldState.day,
            oldState.hour, oldState.minute
            ).getTime();
        // errechneten Timestamp in Instanzvariable schreiben für Radarcontrol für Einzelbildanzeige
        if (duration === 0) {
            this.timestampSingleImage = stateTimestamp;
        }
        // Radarbilder Array muss jedes mal leer sein
        this.radarImageArray = [];
        for (let i = 0; i <= duration; i++) {
            if (i > 0) {
                stateTimestamp = stateTimestamp - 300000;
            }
            this.radarImageArray.push(this.createImageString(stateTimestamp, altState, false));
        }
    };

    // lädt das (neue) Radarbild "Neu laden"
    // - im Loop selbstständig, durch Klicken von Buttons dea RadarControls oder bei "Neu laden"
    loadImage = (buttonClicked, backwards, altState) => {
        const newState = {}; let oldState = {};
        if (buttonClicked) newState["showSidebar"] = false;
        if (buttonClicked) {
            newState["loadNewClicked"] = true;
            if (altState) {
                //altState wird im Moment für andere Station oder Type verwendet
                oldState = altState;
                if (altState.station) newState["station"] = altState.station;
                if (altState.type) newState["type"] = altState.type;
            } else {
                oldState = this.state;
            }
            this.setState({showSpinner: true});              
            if (oldState.station === "10000") {
                newState["backgroundMap"] = this.constBackgroundMap + "800.gif"
            } else {
                newState["backgroundMap"] = this.constBackgroundMap + "1600.gif"
            }
            this.createRadarArray(oldState.animation, altState);
            //letzter Array-Eintrag = ältestes Bild ( = Startbild)
            this.currentIndex = this.radarImageArray.length;
        }
        // Zurücksetzen bei Animationen / Radaranimation-Control
        if(!backwards) {
            if (this.currentIndex === 0) this.currentIndex = this.radarImageArray.length;
            newState["currentRadarImage"] = this.radarImageArray[--this.currentIndex];
        } else {
            if (this.currentIndex === this.radarImageArray.length - 1) this.currentIndex = -1;
            newState["currentRadarImage"] = this.radarImageArray[++this.currentIndex];
        }
        this.setState(newState);
    };

    stopRadarAnimation = () => {
        this.setState({isLoop: false});
        clearTimeout(this.timeoutId);
    };

    continueRadarAnimation = () => {
        this.setState({isLoop: true});
        this.startRadarAnimation();
    };

    // wird durch onload in Radar.js aufgerufen, immer; nur bei animation animation, sonst stop
    startRadarAnimation = () => {
        const animation = this.state.animation;
        let speed = this.state.speed;
        if (this.currentIndex === 0) speed *= 3;
        if (animation !== 0){
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(()=>{
                this.loadImage(false, false);
            }, speed);
        }
    };

    radarControlAnimationHandler = (forward) => {
        this.stopRadarAnimation();
        if (forward) {
            this.loadImage(false, false);
        } else {
            this.loadImage(false, true);
        }
    };

    changeAnimationSpeed = (faster) => {
        let speed = this.state.speed, newSpeed;
        if (faster) {
            newSpeed = speed / 2;
            if (newSpeed < 62.5) newSpeed = speed;
        } else {
            newSpeed = speed * 2;
            if (newSpeed > 2000) newSpeed = speed;
        }
        this.setState({speed: newSpeed});
    };

    radarControlSingleHandler = (forward) => {
        let calcedTime; const newState = {};
        if (forward) {
            this.timestampSingleImage += 300000;
            if (this.timestampSingleImage > this.currentTimestamp) {
                this.timestampSingleImage -= 300000;
                return;
            }
        } else {
            this.timestampSingleImage -= 300000;
        }
        calcedTime = this.modifyTimestamp(this.timestampSingleImage, false);
        newState.year = calcedTime.year;
        newState.month = calcedTime.month;
        newState.day = calcedTime.day;
        newState.hour = calcedTime.hour;
        newState.minute = calcedTime.minute;
        newState.showSpinner = true;
        newState.currentRadarImage = this.createImageString(this.timestampSingleImage);
        this.setState(newState);
    };

    setNewest = () => {
        const isAnimation = this.state.animation !== 0;
        if(isAnimation) {
            this.setNewestRadarLoop();
        } else {
            this.setNewestRadarImage();
        }
    };

    setNewestRadarImage = () => {
        let newState = {}, calcedTime;
        this.stopRadarAnimation();
        calcedTime = this.modifyTimestamp();
        newState.currentRadarImage = this.createImageString();
        newState.year = calcedTime.year;
        newState.month = calcedTime.month;
        newState.day = calcedTime.day;
        newState.hour = calcedTime.hour;
        newState.minute = calcedTime.minute;
        this.setState(newState);
    };


    setNewestRadarLoop = () => {
        let newState = {...this.state}, calcedTime;
        calcedTime = this.modifyTimestamp();
        newState.year = calcedTime.year;
        newState.month = calcedTime.month;
        newState.day = calcedTime.day;
        newState.hour = calcedTime.hour;
        newState.minute = calcedTime.minute;
        this.createRadarArray(newState.animation, newState);
        this.setState(newState);
    };

    toggleDesktopSidebar = () => {
        this.setState((prevState) => {
            return {showSidebarDesktop: !prevState.showSidebarDesktop}
        });
    };

    toggleAutoReload = () => {
        this.clearAutoReload();
        this.setState((prevState) => {
            // state setzen und gleich autoreload-timer starten, wenn loadNewClicked.
            if (!prevState.autoReload && prevState.loadNewClicked) this.startAutoReload(prevState.animation);
            return {autoReload: !prevState.autoReload}
        });
    };

    startAutoReload = (animation) => {
        //einfach jede Minute prüfen, Methoden gucken ja selbst, welches Radarbild das
        if (animation === 0) {
            this.setNewestRadarImage();
            this.autoReloadInterval = setInterval(this.setNewestRadarImage, 60*1000); //jede Minute prüfen
        } else {
            this.setNewestRadarLoop();
            this.autoReloadInterval = setInterval(this.setNewestRadarLoop, 60*1000); //jede Minute prüfen
        }
    };

    clearAutoReload = () => {
        this.autoReloadInterval = clearInterval(this.autoReloadInterval);
    }

    stopSpinner = () => {
        this.setState({showSpinner: false});
    };

    render() {
        return (
            <Fragment>
                <Spinner
                    show={this.state.showSpinner}
                />
                <RadarControl
                    loadNewClicked={this.state.loadNewClicked}
                    animation={this.state.animation}
                    currentRadarImage={this.state.currentRadarImage}
                    stopRadarAnimation={this.stopRadarAnimation}
                    continueRadarAnimation={this.continueRadarAnimation}
                    radarAnimationHandler={this.radarControlAnimationHandler}
                    radarSingleHandler={this.radarControlSingleHandler}
                    changeAnimationSpeed={this.changeAnimationSpeed}
                    animationSpeed={this.state.speed}
                    setNewestImage={this.setNewest}
                    toggleDesktopSidebar={this.toggleDesktopSidebar}
                    showSidebarDesktop={this.state.showSidebarDesktop}
                    viewMode={this.state.viewMode}
                    updateState={this.updateState}
                    isLoop={this.state.isLoop}
                />
                <div className="main-content">
                    <Sidebar
                        show={this.state.showSidebar}
                        showDesktop={this.state.showSidebarDesktop}
                        year={this.state.year}
                        month={this.state.month}
                        day={this.state.day}
                        hour={this.state.hour}
                        minute={this.state.minute}
                        type={this.state.type}
                        station={this.state.station}
                        animation={this.state.animation}
                        map={this.state.map}
                        gpsCoordinates={this.state.gpsTypeCoordinates}
                        gpsType={this.state.gpsType}
                        coordinatesHandler={this.coordinatesClickedHandler}
                        updateState={this.updateState}
                        loadImage={this.loadImage}
                        autoReload={this.state.autoReload}
                        toggleAutoReload={this.toggleAutoReload}
                        loadNewClicked={this.state.loadNewClicked}
                    />
                    <Radar
                        mode={'basic'}
                        type={this.state.type}
                        loadImage={this.loadImage}
                        radarImage={this.state.currentRadarImage}
                        backgroundMap={this.state.backgroundMap}
                        gpsOverlayMap={this.state.gpsOverlayMap}
                        startRadarAnimation={this.startRadarAnimation}
                        startImagesArray={this.state.startImagesArray}
                        compositClicked={this.compositClicked}
                        showDesktop={this.state.showSidebarDesktop}
                        viewMode={this.state.viewMode}
                        stopSpinner={this.stopSpinner}
                        isLoop={this.state.isLoop}
                    />
                </div>
            </Fragment>
        );
    }
}

export default Basic;