import React, {Component, Fragment} from "react";
import SidebarMaps from '../../components/Navigation/Sidebar/SidebarMaps';
import './Maps.css';
import MapsControl from '../../components/Navigation/Sidebar/MapsControl/MapControl';
import stationOverlayCoords from '../../common/stationOverlayCoords';
import stationCoords from '../../common/stationCoords';
import Spinner from "../../components/UI/Spinner/Spinner";
import modifyTimestamp from "../../common/modifyTimestamp";
import { regCheckGPS, regSplitGPS } from "../../common/regex";
import RadarOverlay from "./overlay";
import {OVERLAY, OVERLAY_LOOP, BACKGROUND_LOCATION} from "../../constants/constants"


const google = window.google;



class Maps extends Component {

    state = {
        loaded: false,
        mode: "standardCurrent"
    };

    map = {};

    createOverlay() {
        this.map.overlay = new RadarOverlay(this.map.bounds, this.map.srcImage, this.map.map);
    }

    componentDidMount() {        
        this.map.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 6,
            center: {lat: +51.0, lng: +10.0},
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });  
        this.map.bounds = new google.maps.LatLngBounds(
              new google.maps.LatLng(+46.412054, +4.468541),
              new google.maps.LatLng(+55.378553, +15.888327)
              );
        this.map.srcImage = OVERLAY;
        this.createOverlay();
    }    

    toggleDesktopSidebar = () => {
        this.setState((prevState) => {
            return {showSidebarDesktop: !prevState.showSidebarDesktop}
        });
    };

    coordinatesClickedHandler = (value, type) => { 
        const newState = {};
        if (value === 'geolocation') {           
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    let coordinatesString = Math.round(position.coords.latitude * 100) / 100  + ',' +
                        Math.round(position.coords.longitude * 100) / 100;
                    newState[type+'Coordinates'] = coordinatesString;
                    newState[type] = value;
                    if (type === 'centerType') {
                        newState["stationArray"] = this.getStationsFromPoint(coordinatesString);
                    }
                    this.updateMapMarker(coordinatesString.split(regSplitGPS));
                    this.setState(newState);
                });
            }        
    }
    };

    updateState = (prop, value) => {  
        const newState = {};
        if (prop === 'mode' && value === 'standardLoop') {       
            newState['showSidebar'] = false;
            this.startAnimationHandler(this.map);   
        }
        if (prop === 'mode' && value === 'standardCurrent') {
            newState['showSidebar'] = false;
            this.setCurrentComposit(this.map);

        }
        if(prop === 'showSidebar') {
            newState.showSidebarDesktop = !this.state.showSidebarDesktop;
        } else {
            newState.showSidebarDesktop = false;
        }
        newState[prop] = value;
        this.setState(newState);
    }

    setCurrentComposit = (map) => {
        map.overlay.setImgSource(OVERLAY); 
        map.overlay.setMap(map.map);
    }

    startAnimationHandler = (map) => {
        map.overlay.setImgSource(OVERLAY_LOOP);
        map.overlay.setMap(map.map);
    }

    updateMapMarker = (coords) => {
        const position = new google.maps.LatLng(coords[0], coords[1]);
        if (!this.map.marker) {
            this.map.marker = new google.maps.Marker({
                map: this.map.map,
                position: position
            })
        } else {
            this.map.marker.setPosition(position);
        }
        this.map.marker.setVisible();
    }

    render() {  
         return (
            <Fragment>
                <MapsControl
                    setCurrentImage={this.setCurrentImage}
                    updateLocation={this.coordinatesClickedHandler}
                    changeMapStyle={this.changeMapStyle}
                    changeOpacity={this.changeOpacity}
                    opacity={this.state.opacity*100}
                    mode={this.state.mode}
                    radarSingleHandler={this.radarControlSingleHandler}
                    loadNewClicked={this.state.loadNewClicked}
                    toggleDesktopSidebar={this.toggleDesktopSidebar}
                    showSidebarDesktop={this.state.showSidebarDesktop}
                    timeForShownRadarImage={this.state.timeForShownRadarImage}
                />
                <div className="main-content">
                <SidebarMaps
                    updateState={this.updateState}
                    loadImage={this.loadImage}
                    show={this.state.showSidebar}
                    year={this.state.year}
                    month={this.state.month}
                    day={this.state.day}
                    hour={this.state.hour}
                    minute={this.state.minute}
                    type={this.state.type}
                    centerCoordinates={this.state.centerTypeCoordinates}
                    gpsCoordinates={this.state.gpsTypeCoordinates}
                    gpsType={this.state.gpsType}
                    centerType={this.state.centerType}
                    coordinatesHandler={this.coordinatesClickedHandler}
                    explorermode={this.state.explorermode}
                    station={this.state.station}
                    stationArray={this.state.stationArray}
                    width={this.state.width}
                    height={this.state.height}
                    resolution={this.state.resolution}
                    mode={this.state.mode}
                    stationMode={this.state.stationMode}
                    autoReload={this.state.autoReload}
                    toggleAutoReload={this.toggleAutoReload}
                    showSidebarDesktop={this.state.showSidebarDesktop}
                    toggleDesktopSidebar={this.toggleDesktopSidebar}
                    loadNewClicked={this.state.loadNewClicked}
                />
                <div id="map"></div>
                </div>
            </Fragment>
         )
    }    
}

export default Maps;



