import React from 'react';
import './RadarControlButton.css';

const radarcontrolbutton = (props) => {
    let className;
    if (props.showDesktop) {
        className = "main-content__control__radar-control__item-desktop";
    } else {
        className = "main-content__control__radar-control__item-normal";
    }
    if (props.active) {
        className = className + ' main-content__control__radar-control__item__active';
    }
    return (
        <div className={className}
             onClick={props.clickHandler}>
        {props.children}
        </div>

    );
}

export default radarcontrolbutton;


