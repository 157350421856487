import React from 'react';
import './Date.css';

const date = (props) => {
    let selectYear = [], selectMonth = [], selectDay = [];
    let optionYear = [], optionMonth = [], optionDay = [];
    let initialMilli = new Date().getTime();
    //only today + the last 30 days shoule be selectabe#le
    for(let i = 0; i < 31; i++) {
        let date = new Date(initialMilli);
        if(!selectYear.includes(date.getFullYear())) {
            selectYear.push(date.getFullYear());
        }
        if(!selectMonth.includes(date.getMonth() + 1)) {
            selectMonth.push(date.getMonth() + 1);
        }
        if(!selectDay.includes(date.getDate())) {
            selectDay.push(date.getDate());
        }
        initialMilli -= 24*60*60*1000;
    }
    //sort 
    selectYear.sort(function(a, b) {
        return a - b;
    });
    selectMonth.sort(function(a, b) {
        return a - b;
    });
    selectDay.sort(function(a, b) {
        return a - b;
    });
    optionYear = selectYear.map(value => {
        return <option value={value} key={value}>{value}</option>
    });
    optionMonth = selectMonth.map(value => {
        return <option value={value} key={value}>{value}</option>
    });
    optionDay = selectDay.map(value => {
        return <option value={value} key={value}>{value}</option>
    });

    return (
        <div className="main-content__control__date">
            <div
                className = 'main-content__control__date__label'
            >Zeitpunkt:</div>
            <select name="Jahr" value={parseInt(props.year)} onChange={(event) => {
                return props.updateState('year', event.target.value);
            }}>
                {optionYear}
            </select>
            <select name="Monat" value={parseInt(props.month)} onChange={(event) => {
                return props.updateState('month', event.target.value);
            }}>
                {optionMonth}
            </select>
            <select name="Tag" value={parseInt(props.day)} onChange={(event) => {
                return props.updateState('day', event.target.value);
            }}>
                {optionDay}
            </select>
        </div>
    );
}

export default date;