import React from 'react';
import './Gps.css';
import SidebarButton from '../../../UI/SidebarButton/SidebarButton';

const gps = (props) => {
    let inputClasses = null;
    let input;
    if (props.gpsType) {
        inputClasses = 'input-show';
        if (props.coordinates) {
            if (/^[0-9]{2}\.[0-9]*\s*,\s*[0-9]{1,2}\.[0-9]*$/.test(props.coordinates)) {
                inputClasses += ' input-true';
            } else {
                inputClasses += ' input-false';
            }
        }
        if (props.gpsType === 'manually') {
            input = <input
                className={inputClasses}
                type="text"
                size={10}
                onChange={(event) => {
                    return props.updateState('gpsTypeCoordinates', event.target.value);
                }}
                value={props.coordinates}
            />
        } else {
            input = <input
                        className={inputClasses}
                        type="text"
                        size={10}
                        disabled
                        value={props.coordinates}
                    />
        }
    }
    return (
        <div className="main-content__control__gps">
            <div
                className = 'main-content__control__gps__label'
            >Positionsmarker:</div>
            <SidebarButton
                active={props.gpsType === 'geolocation'}
                updateState={() => {
                    return props.coordinatesHandler('geolocation', 'gpsType');
                }}
            >Ortung
            </SidebarButton>            
            {input}
        </div>
    );
}

export default gps;