import React, {Component, Fragment} from 'react';
import './MapControl.css';
import RadarControlButton from '../../../UI/RadarControlButton/RadarControlButton';
import RadarControlLabel from '../../../UI/RadarControlLabel/RadarControlLabel';
import RadarControlSidebarToggler from '../../../UI/RadarControlSidebarToggler/RadarControlSidebarToggler';

class MapsControl extends Component {

    render() {
        let singleControl, sidebarToggler, shownTime;
        if (this.props.mode === 'standardLoop' || this.props.mode === 'standardCurrent') {
            singleControl = (
                <RadarControlButton clickHandler={this.props.setCurrentImage}>
                    <i className="fas fa-sync"></i>
                </RadarControlButton>
            );
        } else if (this.props.loadNewClicked) {
            singleControl = (
                <Fragment>
                    <RadarControlButton clickHandler={() => {this.props.radarSingleHandler(false);}}>
                        <i className="fas fa-backward"></i>
                    </RadarControlButton>
                    <RadarControlButton clickHandler={() => {this.props.radarSingleHandler(true);}}>
                        <i className="fas fa-forward"></i>
                    </RadarControlButton>
                    <RadarControlButton clickHandler={this.props.setCurrentImage}>
                        <i className="fas fa-sync"></i>
                    </RadarControlButton>
                </Fragment>
            )
        }
        if (!this.props.showSidebarDesktop) {
            sidebarToggler = <i className="fas fa-angle-double-right"></i>;
        }
        if (this.props.timeForShownRadarImage) {
            shownTime = `${this.props.timeForShownRadarImage.hour}:${this.props.timeForShownRadarImage.minute} Uhr`;
        } else {
            shownTime = "";
        }
        return (
            <div className="main-content__control__radar-control">
                <RadarControlButton
                    clickHandler={() => {this.props.updateLocation('geolocation', 'gpsType')}}>
                    <i className="fas fa-map-marker-alt"></i>
                </RadarControlButton>
                {/* {singleControl} */}
                {/* <RadarControlLabel>
                    Deckkraft
                </RadarControlLabel>
                <input type="range"
                       onChange={this.props.changeOpacity}
                       value={this.props.opacity}
                       min={0}
                       max={100}
                /> */}
                <RadarControlSidebarToggler
                    clickHandler={this.props.toggleDesktopSidebar}
                    showDesktop={true}
                >
                    {sidebarToggler}
                </RadarControlSidebarToggler>
                <RadarControlLabel>
                    {shownTime}
                </RadarControlLabel>
            </div>
        );
    }
}
export default MapsControl;
