import React, {Fragment} from 'react';
import './Sidebar.css';
import Date from './Date/Date';
import Time from './Time/Time';
import Animation from './Animation/Animation';
import Gps from './Gps/Gps';
import Map from './Map/Map';
import Station from './Station/Station';
import Type from './Type/Type';
import Arrow from '../../../assets/arrow_down.png';

const sidebar = (props) => {
    let classesSidebar = 'main-content__control', loadNewButton;
    let opener = (
        <div className='main-content__control__opener'
             onClick={() => props.updateState('showSidebar', true)}>
            <img src={Arrow} alt="Arrow"/>
            <div>Einstellungen aufklappen</div>
        </div>
    );
    if (props.show) {
        classesSidebar += ' main-content__control-active';
        opener = null;
    }
    if (!props.showDesktop) {
        classesSidebar += ' main-content__control-noactive'
    }
    if (!props.loadNewClicked) {
        loadNewButton = <button onClick={() => {
            props.loadImage(true);
        }}>Neu Laden</button>
    } else {
        loadNewButton = <button disabled>Alles aktuell</button>
    }
    return (
        <Fragment>
            {opener}
            <div className={classesSidebar}>
                {/* {loadNewButton}                 */}
                <Date
                    updateState={props.updateState}
                    year={props.year}
                    month={props.month}
                    day={props.day}
                />
                <Time
                    updateState={props.updateState}
                    hour={props.hour}
                    minute={props.minute}
                    autoReload={props.autoReload}
                    toggleAutoReload={props.toggleAutoReload}
                    mode={'basic'}
                    showAutoReload={props.loadNewClicked}
                />
                <Animation
                    updateState={props.updateState}
                    animation={props.animation}
                />                
                <Map
                    updateState={props.updateState}
                    type={props.type}
                    map={props.map}
                />
                <Station
                    updateState={props.updateState}
                    station={props.station}
                    type={props.type}
                    mode={'basic'}
                />
                {loadNewButton}
            </div>
        </Fragment>
    );
}

export default sidebar;