//modifiziert currentTime (=Date Object) so, dass das letzte verfügbare Radarbild geladen werden kann
//abhängig der Servergeschwindigkeit
function modifyCurrentTimestamp(currentTime) {

    //const seconds = currentTime.getSeconds();
    const minutes = currentTime.getMinutes().toString();
    const minuteUnit = parseInt(minutes.charAt(minutes.length - 1));
    const minutesTens = minutes.length === 1 ? 0 : parseInt(minutes.charAt(0));

    if ((minuteUnit <= 2)) {
        currentTime.setMinutes((minutesTens - 1)*10+5);  //zb: 32->25
    } else if( minuteUnit <= 7 ) {
        currentTime.setMinutes(minutesTens*10);          //zb: 56->50
    } else {
        currentTime.setMinutes(minutesTens*10+5);        //zb: 59->55
    }
    return currentTime;
}

export default modifyCurrentTimestamp;