import React from 'react';
import './Sidebarclose.css';

const sidebarclose = (props) => {
    return (
        <div className="main-content__control__close" onClick={props.toggleDesktopSidebar}>
            <i className="fas fa-times"></i>
        </div>
    );
};

export default sidebarclose;