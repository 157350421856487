import React, { Component } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './hoc/Layout';
import Public from './containers/Public/Public';
import Nutzung from './components/Nutzung/Nutzung';
import Maps from './containers/Maps/Maps';
// import Login from './components/Login/Login'
// import { Base64 } from 'js-base64';


class App extends Component {

  render() {
    return (
        <BrowserRouter basename="public">
            <Layout>
                <Switch>
                    <Route path="/public" component={Public} />
                    <Route path="/maps" component={Maps} />
                    <Redirect from="/" to="/public" />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
  }
}

export default App;
