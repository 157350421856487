import React from 'react';

const radarareas = (props) => {
    return (
        <map name="ClickComposit">
            <area
                onClick={() => {
                    props.compositClicked({station: '10103'});
                }}
                alt="Borkum" title="Borkum" shape="circle"
                coords={148*props.ratio + "," + 231*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10832'});
                }}
                alt="Türkheim" title="Türkheim" shape="circle"
                coords={360*props.ratio + "," + 788*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10392'});
                }} alt="Prötzel" title="Prötzel" shape="circle"
                coords={645*props.ratio + "," + 338*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10873'});
                }} alt="Isen" title="Isen" shape="circle"
                coords={523*props.ratio + "," + 830*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10950'});
                }} alt="Memmingen" title="Memmingen" shape="circle"
                coords={391*props.ratio + "," + 854*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10908'});
                }} alt="Feldberg" title="Feldberg" shape="circle"
                coords={235*props.ratio + "," + 862*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10605'});
                }} alt="Neuheilenbach" title="Neuheilenbach" shape="circle"
                coords={134*props.ratio + "," + 624*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10629'});
                }} alt="Offenbach" title="Offenbach" shape="circle"
                coords={285*props.ratio + "," + 637*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10410'});
                }} alt="Essen" title="Essen" shape="circle"
                coords={163*props.ratio + "," + 480*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10557'});
                }} alt="Neuhaus" title="Neuhaus" shape="circle"
                coords={455*props.ratio + "," + 581*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10132'});
                }} alt="Boostedt" title="Boostedt" shape="circle"
                coords={379*props.ratio + "," + 180*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10169'});
                }} alt="Rostock" title="Rostock" shape="circle"
                coords={520*props.ratio + "," + 158*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10780'});
                }} alt="Eisberg" title="Eisberg" shape="circle"
                coords={542*props.ratio + "," + 684*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10488'});
                }} alt="Dresden" title="Dresden" shape="circle"
                coords={639*props.ratio + "," + 511*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10339'});
                }} alt="Hannover" title="Hannover" shape="circle"
                coords={354*props.ratio + "," + 360*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10356'});
                }} alt="Ummendorf" title="Ummendorf" shape="circle"
                coords={457*props.ratio + "," + 395*props.ratio + "," + 80*props.ratio}
            />
            <area
                onClick={() => {
                    props.compositClicked({station: '10440'});
                }} alt="Ummendorf" title="Ummendorf" shape="circle"
                coords={308*props.ratio + "," + 490*props.ratio + "," + 80*props.ratio}
            />
        </map>
    );
};

export default radarareas;


