import React, {Fragment} from 'react';
import './RadarControlSingle.css';
import RadarControlButton from '../../../../UI/RadarControlButton/RadarControlButton';

const radarcontrolsingle = (props) => {
    return (
        <Fragment>
            <RadarControlButton clickHandler={() => {props.radarSingleHandler(false);}}>
                <i className="fas fa-backward"></i>
            </RadarControlButton>
            <RadarControlButton clickHandler={() => {props.radarSingleHandler(true);}}>
                <i className="fas fa-forward"></i>
            </RadarControlButton>
            <RadarControlButton clickHandler={() => {props.setNewestImage();}}>
                <i className="fas fa-sync"></i>
            </RadarControlButton>
        </Fragment>
    );
};

export default radarcontrolsingle;


