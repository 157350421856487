import React from 'react';
import './Map.css'
import SidebarButton from '../../../UI/SidebarButton/SidebarButton';

const map = (props) => {
    if (props.type === 'dx' || props.type === 'px250') {
        return (
            <div className="main-content__control__map">
                <div
                    className = 'main-content__control__map__label'
                >Hintergrund:</div>
                <SidebarButton
                    active={props.map === '_topo_color_'}
                    updateState={() => {
                        return props.updateState('map', '_topo_color_');
                    }}>Standard</SidebarButton>
                <SidebarButton
                    active={props.map === '_topo_spc_'}
                    updateState={() => {
                        return props.updateState('map', '_topo_spc_');
                    }}>SPC-Style</SidebarButton>
                <SidebarButton
                    active={props.map === '_topo_grey_'}
                    updateState={() => {
                        return props.updateState('map', '_topo_grey_');
                    }}>Grau</SidebarButton>
            </div>
        );
    } else {
        return null;
    }
}

export default map;