import React from 'react';
import './RadarControlLabel.css';

const radarcontrollabel = (props) => {
    return (
        <div className="main-content__control__radar-control__label">
            {props.children}
        </div>
    );
}

export default radarcontrollabel;


