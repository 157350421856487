import React from 'react';
import './SidebarButton.css'

const sidebarButton = (props) => {
    let className = 'sidebarButton';
    if (props.active) {
        className+=' sidebarButton-active';
    }
    return (
        <div className={className}
             onClick={() => {
                 return props.updateState();
             }}>{props.children}
        </div>
    );
};

export default sidebarButton;