import React, {Fragment} from 'react';
import './Station.css'
import SidebarButton from '../../../UI/SidebarButton/SidebarButton';

const station = (props) => {

    let radarStations = [
        {Composit: '10000'},
        {"Composit Groß": '10001'}        
    ], stationButtons, ref = [];    
    for (let i = 0; i < radarStations.length; i++) {
        ref.push(React.createRef());
    }
    stationButtons = radarStations.map((station, index) => {
        let number, ort, button;
        for (let key in station) {
            number = station[key];
            ort = key;
        }
        if (!props.explorermode) {
            button = <SidebarButton
                key={index}
                active={props.station === number}
                updateState={() => {
                    return props.updateState('station', number);
                }}>{ort}</SidebarButton>
        }        
        return (
            button
        );
    });

    return (
        <Fragment>
            <div className="main-content__control__station">
                <div className="main-content__control__station__label">Radarkarte:</div>
                {stationButtons}
            </div>
        </Fragment>

    );
}

export default station;