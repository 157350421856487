import React from 'react';
import './RadarControLSidebarToggler.css';

const radarControlSidebarToggler = (props) => {
    return (
        <div className="main-content__control__radar-control__item-sidebar"
             onClick={props.clickHandler}>
            {props.children}
        </div>

    );
}

export default radarControlSidebarToggler;


