import React, {Component} from 'react';
import './RadarControl.css';
import RadarControlSingle from './RadarControlSingle/RadarControlSingle';
import RadarControlAnimation from './RadarControlAnimation/RadarControlAnimation';
import RadarControlButton from '../../../UI/RadarControlButton/RadarControlButton';


// Muss Class-Component sein, da Instanzvariable benötigt wird

class RadarControl extends Component {

    render() {
        let viewMode, sidebarToggler;
        if (this.props.currentRadarImage) {
            if (this.props.loadNewClicked) {
                this.props.animation === 0 ?
                    this.control = <RadarControlSingle
                        radarSingleHandler={this.props.radarSingleHandler}
                        setNewestImage={this.props.setNewestImage}/> :
                    this.control = <RadarControlAnimation
                        stopRadarAnimation={this.props.stopRadarAnimation}
                        radarAnimationHandler={this.props.radarAnimationHandler}
                        continueRadarAnimation={this.props.continueRadarAnimation}
                        changeAnimationSpeed={this.props.changeAnimationSpeed}
                        animationSpeed={this.props.animationSpeed}
                        setNewestImage={this.props.setNewestImage}
                        isLoop={this.props.isLoop}
                    />;
            } else {
                this.control = null;
            }
            if (this.props.viewMode === 'responsive') {
                viewMode = 'Full';
            } else {
                viewMode = 'Responsive';
            }
            if (!this.props.showSidebarDesktop) {
                sidebarToggler = <i className="fas fa-angle-double-right"></i>;
            } else {
                sidebarToggler = <i className="fas fa-angle-double-left"></i>;
            }
            return (
                <div className="main-content__control__radar-control">
                    <RadarControlButton
                        clickHandler={this.props.toggleDesktopSidebar}
                        showDesktop={true}
                    >
                        {sidebarToggler}
                    </RadarControlButton>
                    <RadarControlButton
                        clickHandler={() => {
                            return this.props.updateState('viewMode', viewMode.toLowerCase());}
                        }
                    >
                        {viewMode}
                    </RadarControlButton>
                    {this.control}
                </div>
            );
        } else {
            return null;
        }

    }
}
export default RadarControl;
