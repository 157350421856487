import React from 'react';
import './Toggle.css';

const toggle = (props) => {
    let toggleClass;
    if (props.where === 'sidebar') {
        toggleClass= 'toggle-button_sidebar';
    } else {
        toggleClass = 'toggle-button_topbar';
    }
    toggleClass += ' toggle-button';
    return (
        <button className={toggleClass} onClick={props.toggle}>
            <span className="toggle-button__bar"></span>
            <span className="toggle-button__bar"></span>
            <span className="toggle-button__bar"></span>
        </button>
    );
}

export default toggle;
