import React, {Fragment} from 'react';
import './SidebarMaps.css';
import Date from './Date/Date';
import Time from './Time/Time';
import Gps from './Gps/Gps';
import Station from './Station/Station';
import ImageCenter from './ImageCenter/ImageCenter'
import SidebarClose from './SidebarClose/Sidebarclose'
import ImageSize from './ImageSize/ImageSize';
import Resolution from './Resolution/Resolution';
import ExplorerMode from './ExplorerMode/ExplorerMode'
import MapsCompositLoop from './MapsCompositLoop/MapsCompositLoop';
import Arrow from "../../../assets/arrow_down.png";

const sidebarmaps = (props) => {
    let classesSidebar = 'main-content__control-maps';
    let opener = (
        <div className='main-content__control__opener'
             onClick={() => props.updateState('showSidebar', true)}>
            <img src={Arrow} alt="Arrow"/>
            <div>Einstellungen aufklappen</div>
        </div>
    );
    let loadNewButton;
    if (props.show) {
        classesSidebar += ' main-content__control-active';
        opener = null;
    }
    if (props.showSidebarDesktop) {
        classesSidebar += ' main-content__control-maps-open';
    } else {
        classesSidebar += ' main-content__control-maps-close';
    }   
    return (
        <Fragment>
            {opener}
            <div className={classesSidebar}>
                <SidebarClose
                    toggleDesktopSidebar={props.toggleDesktopSidebar}/>
                <Gps
                    gpsType={props.gpsType}
                    coordinatesHandler={props.coordinatesHandler}
                    coordinates={props.gpsCoordinates}
                    updateState={props.updateState}
                />               
                <MapsCompositLoop
                    updateState={props.updateState}
                    mode={props.mode}
                />                
            </div>
        </Fragment>
    );
};

export default sidebarmaps;