const google = window.google;

function RadarOverlay (bounds, image, map) {
    // Initialize all properties.
    this.bounds_ = bounds;
    this.image_ = image;
    this.map_ = map;

    // Define a property to hold the image's div. We'll
    // actually create this div upon receipt of the onAdd()
    // method so we'll leave it null for now.
    this.div_ = null;

    // Explicitly call setMap on this overlay.
    this.setMap(map);
}

RadarOverlay.prototype = new google.maps.OverlayView();

RadarOverlay.prototype.onAdd = function() {
    var div = document.createElement('div');
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        div.style.position = 'absolute';
        div.style.opacity = '0.7';

        // Create the img element and attach it to the div.
        var img = document.createElement('img');
        img.src = this.image_;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        div.appendChild(img);

        this.div_ = div;

        // Add the element to the "overlayLayer" pane.
        var panes = this.getPanes();
        panes.overlayLayer.appendChild(div);
}

RadarOverlay.prototype.draw = function() {
        // We use the south-west and north-east
        // coordinates of the overlay to peg it to the correct position and size.
        // To do this, we need to retrieve the projection from the overlay.
        var overlayProjection = this.getProjection();

        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.
        var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
        var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

        // Resize the image's div to fit the indicated dimensions.
        var div = this.div_;
        div.style.left = sw.x + 'px';
        div.style.top = ne.y + 'px';
        div.style.width = (ne.x - sw.x) + 'px';
        div.style.height = (sw.y - ne.y) + 'px';

}

RadarOverlay.prototype.onRemove = function() {
    this.div_.parentNode.removeChild(this.div_);
    this.div_ = null;
}

RadarOverlay.prototype.setImgSource = function(imgSource) {
    this.image_ = imgSource;
}

// class RadarOverlay extends google.maps.OverlayView {

//     constructor(bounds, image, map) {
//         super();
//         debugger
//         // Initialize all properties.
//         this.bounds_ = bounds;
//         this.image_ = image;
//         this.map_ = map;

//         // Define a property to hold the image's div. We'll
//         // actually create this div upon receipt of the onAdd()
//         // method so we'll leave it null for now.
//         this.div_ = null;

//         // Explicitly call setMap on this overlay.
//         this.setMap(map);
//     }

//     onAdd() {
//         var div = document.createElement('div');
//         div.style.borderStyle = 'none';
//         div.style.borderWidth = '0px';
//         div.style.position = 'absolute';
//         div.style.opacity = '0.7';

//         // Create the img element and attach it to the div.
//         var img = document.createElement('img');
//         img.src = this.image_;
//         img.style.width = '100%';
//         img.style.height = '100%';
//         img.style.position = 'absolute';
//         div.appendChild(img);

//         this.div_ = div;

//         // Add the element to the "overlayLayer" pane.
//         var panes = this.getPanes();
//         panes.overlayLayer.appendChild(div);
//     }

//     draw() {
//         // We use the south-west and north-east
//         // coordinates of the overlay to peg it to the correct position and size.
//         // To do this, we need to retrieve the projection from the overlay.
//         var overlayProjection = this.getProjection();

//         // Retrieve the south-west and north-east coordinates of this overlay
//         // in LatLngs and convert them to pixel coordinates.
//         // We'll use these coordinates to resize the div.
//         var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
//         var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

//         // Resize the image's div to fit the indicated dimensions.
//         var div = this.div_;
//         div.style.left = sw.x + 'px';
//         div.style.top = ne.y + 'px';
//         div.style.width = (ne.x - sw.x) + 'px';
//         div.style.height = (sw.y - ne.y) + 'px';
//     }

//     onRemove() {
//         this.div_.parentNode.removeChild(this.div_);
//         this.div_ = null;
//     }
// } 


export default RadarOverlay;