import React from 'react';
import './Startexplorer.css';
import StartExplorerImage from '../../../assets/explorer_welcome_grafik.gif';
import StartExplorerImage2 from '../../../assets/explorer_welcome_grafik2.gif';

const startexplorer = (props) => {
    return(
        <div className="main-content__radar-start">
            <h1>IRAS 3.0.4 - Bitte Änderungen beachten!</h1>
            <div>
                IRAS_Explorer ist ein Tool für detailiertere Radaranalysen für das DX und PX250 Produkt.
                Dabei kann der Kartenausschnitt und die Bildauflösung frei gewählt werden, um konkret einzelne
                Gewitterzellen besser analyiseren zu können.
                Die Grafiken werden jeweils neu generiert, weshalb hier etwas längere Ladezeiten zu erwarten sind.
            </div>
            <div className="main-content__radar-start__container">
                <div><img src={StartExplorerImage} alt="ExplorerExample"/></div>
                <div><img src={StartExplorerImage2} alt="ExplorerExample"/></div>
            </div>
            <h2>
                Bedienung
            </h2>
            <div>
                Über die linke Leiste erfolgt die Auswahl der Radarbilder. Im Gegensatz zu IRAS Basic steht noch
                keine Animation zur Verfügung. Im Einzelstationsmodus können Daten im Bereich einer Einzelstation
                angezeigt werden, im Kompositmodus erfolgt das Auswählen mehrerer Stationen. In diesem Modus muss die
                Kartenmitte via Koordinateneingabe oder Ortung gesetzt werden, da wir wissen müssen, in welchem Bereich
                die Radargrafik erstellt werden soll. Im Einzelstationsmodus wird die Radarstation als Kartenmitte
                gesetzt. <br/>
                Eine Eingabe der Koordinaten muss dabei in der Form "bb.bb,ll.ll" erfolgen.
                <br/>Für die voll funktionsfähige Nutzung bitte eine neue
                Version von Google Chrome oder Mozilla Firefox verwenden!
            </div>
        </div>
    );
}

export default startexplorer;