import React, {Fragment} from 'react';
import './MapsCompositLoop.css';
import SidebarButton from '../../../UI/SidebarButton/SidebarButton';

const mapscompositloop = (props) => {
    return (
        <Fragment>
            <div className="main-content__control__mapscompositloop">
                <div className="main-content__control__mapscompositloop__label"></div>
                <SidebarButton
                    active={props.mode === 'standardCurrent'}
                    updateState={() => {
                        return props.updateState('mode', 'standardCurrent');
                    }}>Aktuellstes Radarbild
                </SidebarButton>
                <SidebarButton
                    active={props.mode === 'standardLoop'}
                    updateState={() => {
                        return props.updateState('mode', 'standardLoop');
                    }}>aktuelle -0,5h Animation
                </SidebarButton>
            </div>
        </Fragment>
    );
}

export default mapscompositloop;