import React from 'react';
import './Animation.css';
import SidebarButton from '../../../UI/SidebarButton/SidebarButton';

const animation = (props) => {
    return (
        <div className="main-content__control__animation">
            <div
                className = 'main-content__control__animation__label'
            >Animation (in Stunden):</div>
            <SidebarButton
                active={props.animation === 288}
                updateState={() => {
                    return props.updateState('animation', 288);
                }}>-24</SidebarButton>
            <SidebarButton
                active={props.animation === 144}
                updateState={() => {
                    return props.updateState('animation', 144);
                }}>-12</SidebarButton>
            <SidebarButton
                active={props.animation === 72}
                updateState={() => {
                    return props.updateState('animation', 72);
                }}>-6</SidebarButton>
            <SidebarButton
                active={props.animation === 36}
                updateState={() => {
                    return props.updateState('animation', 36);
                }}>-3</SidebarButton>
            <SidebarButton
                active={props.animation === 18}
                updateState={() => {
                    return props.updateState('animation', 18);
                }}>-1,5</SidebarButton>
            <SidebarButton
                active={props.animation === 12}
                updateState={() => {
                    return props.updateState('animation', 12);
                }}>-1</SidebarButton>
            <SidebarButton
                active={props.animation === 6}
                updateState={() => {
                    return props.updateState('animation', 6);
                }}>-0,5</SidebarButton>
            <SidebarButton
                active={props.animation === 0}
                updateState={() => {
                    return props.updateState('animation', 0);
                }}>0</SidebarButton>
        </div>
    );
}

export default animation;