import React, {Component, Fragment} from 'react';

import Topbar from '../components/Navigation/Topbar/Topbar';
import './Layout.css'

class Layout extends Component {

    state = {
        showTopbar: false
    }

    topbarToggle = () => {
        this.setState((prevState) => {
            return {
                showTopbar: !prevState.showTopbar
            }
        });
    }


    render() {
        return(
            <Fragment>
                <Topbar
                    show={this.state.showTopbar}
                    toggle={this.topbarToggle}></Topbar>
                {this.props.children}
            </Fragment>
        )
    }
}

export default Layout;
